import { getSections } from "./shared/sections";
import PageLayout from "@/components/PageLayout";
export default {
  name: "index",
  components: {
    PageLayout
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    sidebarLinks() {
      const scope = this.user && this.user.scopes[0];
      return getSections(scope);
    }
  }
};